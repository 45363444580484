<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="disableSaveButton"
        @vasionButtonClicked="save"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="edit-database-lookup-details">
        <div class="row">
          <AutomationEngineSharedHeader
            ref="header"
            :nameLabel="'SCHEDULED LOOKUP NAME'"
            @permissions="showMainSection = false"
            @general-automation="showMainSection = true"
            @dirty="isDirty = true"
          />
        </div>
        <div v-if="showMainSection" class="row">
          <span class="subheader">
            Build Lookup
          </span>
        </div>
        <div v-if="showMainSection" class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="lookupAttribute"
            class="row-field"
            :dataArray="lookupList"
            width="396"
            title="Lookup"
            placeholder="Select Lookup..."
            type="plain-list"
            displayName="name"
            valueName="id"
            @input="isDirty = true"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>

        <div v-if="showMainSection" class="row">
          <VasionCheckbox
            id="use-specific-folder"
            name="use-specific-folder"
            class="row-field checkbox"
            :checked="useSpecificFolder"
            @change="toggleUseSpecificFolder"
          >
            Lookup in Specific Folder
          </VasionCheckbox>
        </div>
        <div v-if="showMainSection" v-show="useSpecificFolder" class="row">
          <VasionInput
            id="export-from"
            v-model="folderName"
            class="row-field browse-input"
            title="FOLDER"
            inputType="top-white"
            name="export-from"
            placeholder="Select Folder..."
            :width="'306'"
          />
          <VasionButton
            id="export-from-folder-button"
            class="browse-button"
            :classProp="'primary'"
            @vasionButtonClicked="toggleBrowseFolderDialog('from-folder')"
          >
            Browse
          </VasionButton>
        </div>
        
        <div v-if="showMainSection" class="row">
          <VasionCheckbox
            id="move-completed-documents-checkbox"
            name="move-completed-documents-checkbox"
            class="row-checkbox"
            :checked="moveCompletedDocuments"
            @change="moveCompletedDocuments = !moveCompletedDocuments; isDirty = true"
          >
            Move Completed Documents
          </VasionCheckbox>
        </div>
        <div v-if="showMainSection" v-show="moveCompletedDocuments" class="row">
          <VasionInput
            id="move-to-folder"
            v-model="selectedMoveToFolder"
            class="row-field browse-input"
            title="MOVE TO FOLDER"
            :readonly="true"
            inputType="top-white"
            name="move-to-folder"
            placeholder="Select Folder..."
            :width="'306'"
          />
          <VasionButton
            id="move-to-folder-button"
            class="browse-button"
            :classProp="'primary'"
            @vasionButtonClicked="toggleBrowseFolderDialog('move-to-folder')"
          >
            Browse
          </VasionButton>
        </div>
      </div>
    </div>
    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="showBrowseFoldersDialog = false"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          @vasionButtonClicked="folderDialogOK"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'AutomationEngineEditDatabaseLookup',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      attributeFormId: 0,
      fileBrowseField: '',
      folderId: 0,
      folderName: '',
      isDirty: false,
      lookupAttribute: '',
      lookupId: 0,
      lookupName: '',
      moveCompletedDocuments: false,
      schedulerId: 0,
      schedulerTitle: '',
      selectedMoveToFolder: '',
      selectedMoveToFolderId: null,
      showAddConnectionDialog: false,
      showBrowseFoldersDialog: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      useSpecificFolder: false,
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    disableSaveButton() { return this.lookupSelected || !this.isDirty },
    lookupList() {
      return this.$store.state.fieldMapping.lookupList.map((f) => {
        return {
          name: f.name,
          id: f.id,
        }
      })
    },
    lookupSelected() { return this.lookupAttribute && this.lookupAttribute.name > 0 },
    selectedFolder() { return this.$store.state.common.selectedFolder },
  },
  async created() {
    if (!this.lookupList || this.lookupList.length === 0) {
      await this.$store.dispatch('fieldMapping/getDatabaseLookups', true)
    }
    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getLookupDetails', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setSchedulerValues()
      }
    }
    this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Lookup'
    this.isDirty = false
  },
  methods: {
    cancel() { this.$router.push({ name: 'AutomationEngineDatabaseLookups' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true) {
        this.isDirty = true
        if (this.fileBrowseField === 'from-folder') {
          // Set folder info here, including ID and name
          this.folderId = this.selectedFolder.item.FolderId
          this.folderName = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'move-to-folder') {
          this.selectedMoveToFolderId = this.selectedFolder.item.FolderId
          this.selectedMoveToFolder = this.selectedFolder.item.Name
        }
      }
      this.toggleBrowseFolderDialog()
      this.$store.dispatch('common/setSelectedFolder', null)
    },
    async save() {
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          lookupID: this.lookupAttribute.id,
          folderID: this.useSpecificFolder ? this.folderId: null,
          useSpecificFolder: this.useSpecificFolder,
          completedFolderID: this.moveCompletedDocuments ? this.selectedMoveToFolderId: null,
        }
        if (await this.$store.dispatch('automationEngine/saveLookupScheduler', payload)) {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'Database Lookup saved successfully!'
          this.showSnackbar = true
          this.snackbarImage = true
          this.$router.push({ name: 'AutomationEngineDatabaseLookups' })
        } else {
          this.snackbarTitle = 'ERROR'
          this.snackbarText = 'There was an error saving the database lookup'
          this.showSnackbar = true
          this.snackbarImage = false
          this.$router.push({ name: '[AutomationEngineDatabaseLookups]' })
        }
      }
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setSchedulerValues() {
      // Set all the non-header values here, for an existing scheduler
      this.schedulerId = this.activeScheduler.runDetails.schedulerID
      this.lookupId = this.activeScheduler.lookupID
      this.folderId = this.activeScheduler.folderID
      this.folderName = this.activeScheduler.folderName
      this.useSpecificFolder = this.activeScheduler.useSpecificFolder

      if (this.lookupId) {
        this.lookupAttribute = this.lookupList.find((lookup) => {
          return lookup.id === this.lookupId
        })
      }
      
      this.selectedMoveToFolderId = this.activeScheduler.completedFolderID
      this.selectedMoveToFolder = this.activeScheduler.completedFolderName
      this.moveCompletedDocuments = this.selectedMoveToFolderId && this.selectedMoveToFolderId > 0
    },
    toggleBrowseFolderDialog(field) {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog

      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleUseSpecificFolder() {
      this.isDirty = true
      this.useSpecificFolder = !this.useSpecificFolder
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors}`
      }
      const form = this.lookupAttribute
      if (!form || (!typeof type === 'object')
        || (!form.name || form.name === '')
        || (!form.id || form.id === '')) {
          errorText = 'Please provide a lookup. '
          error = true
      }

      if (this.useSpecificFolder && (!this.folderId || this.folderId == 0)) {
          errorText = 'Please select a specific folder.'
          error = true
      }

      if (this.moveCompletedDocuments && (!this.selectedMoveToFolderId || this.selectedMoveToFolderId == 0)) {
          errorText = 'Please select a folder for completed documents.'
          error = true
      }

      if (error) {
        this.snackbarTitle = 'ERROR: '
        this.snackbarText = errorText
        this.showSnackbar = true
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  #edit-database-lookup-details {
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .section-title {
        @include SECTION-HEADER;
        color: $grey-400;
        display: block;
        margin: 5px 0;
      }

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 390px;
        }
      }

      .browse-button {
        button {
          margin-top: 22px;
          margin-left: 0;
          margin-right: 13px;
        }
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }
</style>
